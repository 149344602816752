<template>
  <page-scaffold>
    <card-content :is-loading="isLoading" name="Видеонаблюдение">


      <div class="video-camera-page" v-if="state!=='processing'">
        <div class="text" style="grid-area: title; font-size: 2.2rem; line-height: 2.2rem">{{ camera.title }}</div>
        <div class="text" style="grid-area: price; font-size: 1.8rem; line-height: 1.8rem">{{ camera.pricePerMonth }}
          Руб/мес
        </div>
        <div class="text" style="grid-area: spec-title; font-size: 1.2rem; line-height: 1.2rem">Характеристики:</div>
        <div class="text"
             style="text-align: justify; grid-area: spec; font-size: 1rem; line-height: 1rem; color: #5d5d5d">
          <div v-for="(spec, index) in camera.specifications" style="text-align: start" v-bind:key="index">
            {{ spec }}<br>
          </div>
        </div>
        <div class="widget-add" v-if="state==='notActive' || state==='active'">
          <img src="@/assets/ic_btn_minus.svg" style="height: 32px; align-self: center; cursor: pointer" alt=""
               @click="removeCamera">
          <div class="count">{{ quantity }}</div>
          <img src="@/assets/ic_btn_plus.svg" style="height: 32px; align-self: center; cursor: pointer" alt=""
               @click="addCamera">
          <div class="btn" style="cursor: pointer" @click="connect">Подключить</div>
        </div>

        <img class="image-pop-up-main-image"
             :src="camera.photos[0]"
             alt=""/>
        <div class="image-pop-up-list-images">
          <img v-for="(image, index) in camera.photos" v-bind:key="index" class="image-pop-up-item"
               :src="image"
               alt=""/>
        </div>
      </div>

      <div v-if="state==='processing'"
           style="text-align: center; width: max-content; height: max-content; position: absolute; margin: auto; top: 0; left:0; right: 0; bottom: 0">
        <img :src="require('@/assets/ic_tariff_changing.svg')"/>
        <div class="title">Запрос в обработке</div>
        <div class="date">Номер заявки: {{ appNumber }}</div>
        <div class="btnn" @click="this.$router.push('/chat')">Служба поддержки</div>
      </div>
    </card-content>

  </page-scaffold>
  <pop-up-message v-if="isMessageVisible" :message="message" @close="isMessageVisible=false"/>

</template>

<script>
import PageScaffold from "./page-scaffold";
import CardContent from "./card-content";
import axios from "axios";
import PopUpMessage from "./pop-up-message";

export default {
  name: "page-videocamera",
  components: {PopUpMessage, CardContent, PageScaffold},
  data: () => {
    return {
      isMessageVisible: false,
      appNumber: String,
      message: '',
      isLoading: true,
      quantity: 0,
      camera: {
        photos: ["kek"]
      },
      state: String
    }
  },
  methods: {
    addCamera() {
      this.quantity++
    },
    removeCamera() {
      if (this.quantity > 0)
        this.quantity--
    },
    connect() {
      if (this.quantity > 0) {
        this.isLoading = true
        axios({
          method: "POST",
          url: "v1/services/order/videoSurveillance",
          headers: {Authorization: `Bearer ` + localStorage.token},
          params: {
            quantity: this.quantity
          }
        }).then(response => {
          this.isLoading = false
          this.state = 'processing'
          this.appNumber = response.data.response.applicationNumber
        })
      }
    },
    getInfo() {
      this.isLoading = true
      axios({
        method: "GET",
        url: "v1/services/videoSurveillance",
        headers: {Authorization: `Bearer ` + localStorage.token},
      }).then(response => {
        this.isLoading = false
        this.camera = response.data.response.data
        this.state = response.data.response.state
        if (this.state === 'processing')
          this.appNumber = response.data.response.data.applicationNumber

      })
    }

  },
  mounted() {
    this.getInfo()

  }
}
</script>

<style scoped>

.title {
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #4d9ec4;
  justify-self: center;
  font-size: 1.5rem;
}

.date {
  margin-top: 16px;
  font-family: "Mont", serif;
  font-weight: bold;
  color: #858585;
  justify-self: center;
  font-size: 1.2rem;
}

.btnn {
  cursor: pointer;
  margin-top: 16px;
  border-radius: 10px;
  background: #58B1DA;
  color: white;
  justify-self: center;
  font-family: "Mont", serif;
  padding: 16px 24px 13px 24px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.video-camera-page {
  text-align: start;
  padding: 16px;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  max-height: 100%;
  display: grid;
  overflow-y: hidden;
  grid-template-columns: 1fr  1fr;
  grid-template-rows: max-content max-content max-content 1fr max-content;
  grid-template-areas:
      "title picture"
      "price picture"
      "spec-title picture"
      "spec picture"
      "widget-add pictures"
}

.image-pop-up-main-image {
  object-fit: contain;
  cursor: pointer;
  grid-area: picture;
  height: 100%;
}

.widget-add {
  padding: 0 16px;
  vertical-align: center;
  display: grid;
  grid-template-columns: repeat(3, max-content) 1fr;
  height: 56px;
  align-self: end;
  margin-left: 16px;
  margin-right: 24px;
  margin-bottom: 16px;
  box-shadow: 0 0 24px rgba(29, 132, 201, 0.6);
  width: 400px;
  background: #58B1DA;
  border-radius: 16px;
  grid-area: widget-add;
}

.widget-add .btn {
  border-radius: 10px;
  align-self: center;
  background: white;
  color: #4d9ec4;
  font-family: "Mont", serif;
  padding: 10px 24px 8px 24px;
  justify-self: end;
  margin-left: 16px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.widget-add .count {
  border-radius: 10px;
  align-self: center;
  background: #4d9ec4;
  color: white;
  font-family: "Mont", serif;
  padding: 8px 12px;
  margin-left: 16px;
  margin-right: 16px;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.text {
  margin-right: 32px;
  font-family: "Mont", serif;
  margin-top: 16px;
  margin-left: 16px;
  font-weight: bold;
  color: #111111;
  font-size: 1.4rem;
  line-height: 1.4rem;
}


.image-pop-up-list-images::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.image-pop-up-list-images {
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  overflow-x: auto;
  grid-area: pictures;
}

.image-pop-up-item {
  object-fit: contain;
  margin-right: 16px;
  cursor: pointer;
  min-height: 100px;
  max-height: 100px;
  min-width: 100px;
}

</style>