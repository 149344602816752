<template>
  <page-scaffold>
    <div class="my-tariffs-page" style="position: relative">
      <pop-up-loading style="text-align: center" v-if="isLoading" :opacity="0.5"/>
      <div class="title" style="grid-area: title-internet">Интернет</div>
      <div class="title" v-if="tvTariffs.length!==0" style="grid-area: title-tv">Телевидение</div>
      <div class="title" style="grid-area: title-others">Другие</div>
      <div class="internet-container">
        <div class="internet" v-for="iTariff in internetTariffs" v-bind:key="iTariff.id"
             :style="'background:'+appearance.internet.color">
          <img :src="appearance.internet.icon" alt="" style="height: 100px; margin-right: 16px; grid-area: icon">
          <div class="text" style="grid-area: title; font-size: 1.4rem; align-self: center"> {{ iTariff.title }}
          </div>
          <div class="text" style="grid-area: description; margin-top: 8px">Скорость: до {{ iTariff.maxSpeed }} Мбит/с
          </div>

          <div v-if="isLegalEntity" class="text" style="grid-area: address; margin-top: 8px">
            Адрес: {{ iTariff.address }}
          </div>

          <div class="text" style="grid-area: state; margin-top: 8px">
            Статус: {{ iTariff.state }}
          </div>


          <div class="text" style="grid-area: price; margin-top: 8px; font-size: 1.4rem">{{ iTariff.price }} ₽/ мес
          </div>

          <div v-if="iTariff.state === 'Заблокировано пользователем' || iTariff.state === 'Работает'" class="text"
               style="cursor: pointer; grid-area: block; text-decoration: underline; justify-self: end; align-self: end; margin-right: 24px; margin-bottom: 6px"
               @click="iTariff.state === 'Заблокировано пользователем' ? unblockTariff(iTariff.vgroupID) : blockTariff(iTariff.vgroupID)">
            {{ iTariff.state === 'Заблокировано пользователем' ? 'Разблокировать' : 'Заблокировать' }}
          </div>

          <!--          {{iTariff.state === 'Заблокировано пользователем' ? 'Разблокировать' : 'Заблокировать' }}-->
          <div v-if="!isLegalEntity" class="change" style="cursor: pointer; margin-top: 24px"
               @click="$router.push('/tariffs?type=internet')">СМЕНИТЬ
          </div>
        </div>

      </div>
      <div class="tv-container" v-if="tvTariffs.length!==0">
        <div class="tv" :style="'background:'+appearance.tv.color" v-for="tvTariff in tvTariffs"
             v-bind:key="tvTariff.id">
          <img :src="appearance.tv.icon" alt="" style="height: 100px; margin-right: 16px; grid-area: icon">
          <div class="text" style="grid-area: title; font-size: 1.4rem; align-self: center"> {{ tvTariff.title }}
          </div>
          <div v-if="isLegalEntity" class="text" style="grid-area: address; margin-top: 8px">
            Адрес: {{ tvTariff.address }}
          </div>
          <div class="text" style="grid-area: state; margin-top: 8px">
            Статус: {{ tvTariff.state }}
          </div>
          <div class="text" style="grid-area: price; margin-top: 8px; font-size: 1.4rem">{{ tvTariff.price }} ₽/ мес
          </div>
          <div v-if="!isLegalEntity" class="change" style="cursor: pointer; margin-top: 24px; color: #D34B7F"
               @click="$router.push('/tariffs?type=tv')">СМЕНИТЬ
          </div>
          <div v-if="tvTariff.state === 'Заблокировано пользователем' || tvTariff.state === 'Работает'" class="text"
               style="cursor: pointer; grid-area: block; text-decoration: underline; justify-self: end; align-self: end; margin-right: 24px; margin-bottom: 6px"
               @click="tvTariff.state === 'Заблокировано пользователем' ? unblockTariff(tvTariff.vgroupID) : blockTariff(tvTariff.vgroupID)">
            {{ tvTariff.state === 'Заблокировано пользователем' ? 'Разблокировать' : 'Заблокировать' }}
          </div>
        </div>
      </div>
      <div class="others">
        <div class="other-item" :style="'background:'+appearance.staticIP.color"
             v-for="(staticIp, index) in services.staticIP" v-bind:key="index"
             @click="$router.push(appearance.staticIP.route)">
          <img :src="appearance.staticIP.icon" alt="" style="height: 64px; margin-right: 16px; grid-area: icon">
          <div class="text" style="grid-area: title; font-size: 1.4rem; align-self: center">
            {{ appearance.staticIP.title }}
          </div>
          <div class="text" style="grid-area: description; opacity: 80%; align-self: start">
            {{ staticIp.serviceData.description }}
          </div>
          <div class="text"
               style="grid-area: price; margin-top: 32px;  align-self: end; font-size: 1.4rem; justify-self: end">
            {{ staticIp.serviceData.pricePerMonth }}
            ₽/мес
          </div>
        </div>

        <div class="other-item" @click="$router.push(appearance.video.route)"
             :style="'background:'+appearance.video.color"
             v-for="(video, index) in services.videoSurveillance" v-bind:key="index">
          <img :src="appearance.video.icon" alt="" style="height: 64px; margin-right: 16px; grid-area: icon">
          <div class="text" style="grid-area: title; font-size: 1.4rem; align-self: center"> {{
              appearance.video.title
            }}
          </div>
          <div class="text" style="grid-area: description; opacity: 80%">{{ video.serviceData.description }}</div>
          <div class="text"
               style="grid-area: price; align-self: end; margin-top: 32px; font-size: 1.4rem; justify-self: end">
            {{ video.serviceData.pricePerMonth }}
            ₽/мес
          </div>
        </div>

        <div v-if="isSmartHouse" @click="$router.push(appearance.smartHouse.route)" class="other-item"
             :style="'background:'+appearance.smartHouse.color">
          <img :src="appearance.smartHouse.icon" alt="" style="height: 64px; margin-right: 16px; grid-area: icon">
          <div class="text" style=" align-self: start; grid-area: title; font-size: 1.4rem;">
            {{ appearance.smartHouse.title }}
          </div>
          <div class="text" style="grid-area: description; opacity: 80%;  align-self: start;">{{
              smartHome.description
            }}
          </div>
          <div class="text" style="grid-area: price; font-size: 1.4rem; align-self: end; justify-self: end">
            {{ smartHome.pricePerMonth }}
            ₽/мес
          </div>
        </div>

      </div>
    </div>

  </page-scaffold>

</template>

<script>
import PageScaffold from "./page-scaffold";
import axios from "axios";
import PopUpLoading from "./pop-up-loading";

export default {
  name: "page-my-tariffs",
  components: {PopUpLoading, PageScaffold},
  data: () => {
    return {
      isLoading: true,
      isLegalEntity: Boolean,
      isSmartHouse: false,
      isStaticIP: Boolean,
      isVideo: Boolean,
      appearance: {
        internet: {
          icon: require('@/assets/ic_internet.svg'),
          color: "#64b949"
        },
        tv: {
          icon: require('@/assets/ic_tv.svg'),
          color: "#D34B7F"
        },
        staticIP: {
          route: '/service?type=staticIP',
          title: "Статический IP",
          icon: require('@/assets/ic_ip.svg'),
          color: "#3E39CC"
        },
        vacation: {
          route: '/service?type=vacation',
          title: "Опция \"В отпуске\"",
          icon: require('@/assets/ic_holiday.svg'),
          color: "#ED872D"
        },
        smartHouse: {
          route: '/smart-house',
          title: "Умный дом",
          icon: require('@/assets/ic_smart_house.svg'),
          color: "#9B59B6"
        },
        simCard: {
          title: "SIM-Карты",
          icon: require('@/assets/ic_sim.svg'),
          color: "#1ABC9C"
        },
        video: {
          route: '/service?type=staticIP',
          title: "Видеонаблюдение",
          icon: require('@/assets/ic_camera.svg'),
          color: "#3498DB"
        },
      },
      tvTariffs: Array,
      internetTariffs: Array,
      services: {
        type: Object,
        default: () => {
          return {
            staticIP: null,
            videoSurveillance: null,
          }
        }
      },
      smartHome: {
        description: "Подключено датчиков",
        pricePerMonth: 0
      }
    }
  },
  methods: {
    unblockTariff(id) {
      let self = this
      this.isLoading = true
      axios.delete('v1/tariffs/block', {
        data: {
          vgroupID: id
        },
        headers: {
          Authorization: 'Bearer ' + localStorage.token
        },

      }).then(function (response) {
        if (response.data.status === "OK") {
          axios({
            method: "GET",
            url: "v2/account",
            headers: {Authorization: `Bearer ` + localStorage.token},
          }).then(response2 => {
            self.tvTariffs = response2.data.account.televisionTariffs
            self.internetTariffs = response2.data.account.internetTariffs
            self.isLoading = false
          })
        } else {
          self.isLoading = false
        }

      })
    },

    blockTariff(id) {
      let self = this
      this.isLoading = true
      axios.post('v1/tariffs/block', {
        vgroupID: id,
      }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token
        },

      }).then(function (response) {
        if (response.data.status === "OK") {
          axios({
            method: "GET",
            url: "v2/account",
            headers: {Authorization: `Bearer ` + localStorage.token},
          }).then(response2 => {
            self.tvTariffs = response2.data.account.televisionTariffs
            self.internetTariffs = response2.data.account.internetTariffs
            self.isLoading = false
          })
        } else {
          self.isLoading = false
        }
      })
    },
    openInstr() {
      window.open('https://24h.freshdesk.com/support/solutions/folders/35000206389', '_blank')
    }
  }
  ,
  mounted() {
    this.isLoading = true
    this.isLegalEntity = localStorage.userType === "legalEntity"
    axios({
      method: "GET",
      url: "v2/account",
      headers: {Authorization: `Bearer ` + localStorage.token},
    }).then(response => {
      this.tvTariffs = response.data.account.televisionTariffs
      this.internetTariffs = response.data.account.internetTariffs
      axios({
        method: "GET",
        url: "v1/services",
        headers: {Authorization: `Bearer ` + localStorage.token},
      }).then(response => {
        this.isSmartHouse = response.data.response.smartHome !== null
        this.services = response.data.response
        this.smartHome = response.data.response.smartHome
        this.isLoading = false

      })
    })

  }
}
</script>

<style scoped>

.my-tariffs-page {
  padding-left: 16px;
  width: calc(100% - 16px);
  height: 100%;
  display: grid;
  overflow-y: scroll;
  text-align: start;
  /*grid-template-columns: minmax(100px, 1fr) 1.7fr 1fr;*/
  /*grid-template-columns: 40% 60%;*/
  /*grid-template-columns: repeat(auto-fill, 1fr);*/
  grid-template-columns: 35% 35% 30%;
  grid-template-rows: max-content 1fr;
  grid-template-areas:
      "title-internet title-tv title-others"
      "internet tv others"

}

.title {
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #2D666E;
  grid-area: title;
  justify-self: start;
  white-space: nowrap;
  font-size: 1.4rem;
  margin-top: 24px;
  margin-bottom: 24px;
}

.others {
  height: max-content;
  grid-area: others;
  /*width: calc(100% - 16px);*/
  display: grid;
  /*grid-template-columns: 50% 50%;*/
}

.other-item {
  cursor: pointer;
  padding: 16px;
  border-radius: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  height: max-content;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content max-content 1fr;
  grid-template-areas:
      "icon title"
      "icon description"
      "price price";
}

.text {
  font-family: "Mont", serif;
  font-weight: bold;
  color: #ffffff;
  grid-area: title;
  font-size: 1rem;
}

.internet-container {
  grid-area: internet;
}

.tv-container {
  grid-area: tv;
}

.tv {
  margin-right: 24px;
  background: rgba(153, 0, 255, 0.99);
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
  height: max-content;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: max-content max-content max-content max-content;
  grid-template-areas:
      "icon title title"
      "icon address address"
      "icon state state"
      "icon price price"
      "icon block change";
}

.internet {
  margin-right: 24px;
  grid-area: internet;
  background: rgba(0, 255, 128, 0.99);
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
  height: max-content;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: max-content max-content max-content max-content max-content 1fr;
  grid-template-areas:
      "icon title title"
      "icon description description"
      "icon address address"
      "icon state state"
      "icon price price"
      "icon block change";
}

.change {
  justify-self: end;
  grid-area: change;
  align-self: end;
  padding: 10px 16px 8px 16px;
  width: max-content;
  border-radius: 24px;
  bottom: 16px;
  right: 16px;
  background: white;
  color: #64b949;
  font-family: "Mont", serif;
  font-weight: bold;
  line-height: 1rem;
  font-size: 1rem;
}


</style>