<template>
  <page-scaffold>
    <card-content :is-loading="isLoading" :name="title" style="height: calc(100% - 24px)">
      <div class="content">
        <div class="data" style="position: relative">
          <div class="text" style="grid-area: title; font-size: 2.2rem; line-height: 2.2rem">Условия программы</div>
          <div class="text" style="text-align:justify; grid-area: spec; font-size: 1.1rem; line-height: 1.3rem; color: #5d5d5d">
            Описание условий
          </div>
        </div>
      </div>
    </card-content>
  </page-scaffold>
</template>

<script>
import PageScaffold from "./page-scaffold";
import CardContent from "./card-content";

export default {
  name: "page-recomend-term",
  components: {CardContent, PageScaffold},
  data: () => {
    return {
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

.title {
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #4d9ec4;
  justify-self: center;
  font-size: 1.3rem;
}

.date {
  margin-top: 16px;
  font-family: "Mont", serif;
  font-weight: bold;
  color: #858585;
  justify-self: center;
  font-size: 1.2rem;
}

.btnn {
  cursor: pointer;
  margin-top: 16px;
  border-radius: 10px;
  background: #58B1DA;
  color: white;
  justify-self: center;
  font-family: "Mont", serif;
  padding: 16px 24px 13px 24px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.content .data .text {
  margin-right: 32px;
  font-family: "Mont", serif;
  margin-top: 16px;
  margin-left: 16px;
  font-weight: bold;
  color: #111111;
  font-size: 1rem;
  line-height: 1.4rem;
}

.content {
  width: 100%;
  height: calc(100% - 32px);
  display: grid;
  grid-template-columns: 1fr 1fr;

}

.content .image {
  align-self: center;
  min-height: 50px;
  margin-left: 64px;
  margin-right: 64px;
  width: calc(100% - 128px);
  max-height: calc(100% - 256px);
}
.content .qr {
  align-self: center;
  min-height: 50px;
  margin-top: 64px;
  margin-left: 64px;
  margin-right: 64px;
  width: calc(100% - 128px);
  max-height: calc(100% - 256px);
}

.content .data {
  text-align: start;
  padding: 24px;
}

.btn-container {
  cursor: pointer;
  box-shadow: 0 0 16px rgba(88, 177, 218, 0.6);
  height: 48px;
  margin-left: 16px;
  width: 300px;
  border-radius: 12px;
  padding: 0 48px;
  display: grid;
  background: #58B1DA;
}

.btn-container .btn-text {
  font-family: "Mont", serif;
  margin-top: 4px;
  font-weight: bold;
  color: white;
  font-size: 1.2rem;
  line-height: 1.2rem;
  align-self: center;
  justify-self: center;
}

</style>