<template>
  <pop-up-message :message="message" v-if="isMessageVisible" v-on:close="isMessageVisible=false"/>
  <page-scaffold>
    <card-content :with-button="true" :is-loading="isLoading" :name="this.title" @btnClick="askDocuments">
      <div style="padding: 24px">
        <item-document v-for="document in documents" v-bind:key="document.id" :document="document"/>
      </div>
    </card-content>
  </page-scaffold>

</template>

<script>
import PageScaffold from "./page-scaffold";
import CardContent from "./card-content";
import ItemDocument from "./item-document";
import PopUpMessage from "./pop-up-message"
import axios from "axios";

export default {
  name: "page-documents",
  components: {ItemDocument, CardContent, PageScaffold, PopUpMessage},
  data: () => {
    return {
      isMessageVisible: false,
      message: '',
      type: '',
      title: "Акты",
      documents: Array,
      isLoading: true
    }
  },
  methods: {
    askDocuments() {
      this.isLoading = true
      axios({
        method: "POST",
        url: "v1/documents/order",
        headers: {Authorization: `Bearer ` + localStorage.token},
        params: {
          type: this.type
        }
      }).then(response => {
        this.isLoading = false
        if (response.data.status === "OK") {
          this.message = "Документы запрошены"
        } else
          this.message = "Не удалось запросить\nдокументы"
        this.isMessageVisible = true
      })
    }
  }
  ,
  mounted() {
    this.isLoading = true
    switch (this.$route.query.page) {
      case "0":
        this.title = "Акты сверки"
        this.type = "reconciliationReport"
        break;
      case "1":
        this.title = "Счета на оплату"
        this.type = "paymentInvoice"
        break;
      case "2":
        this.title = "Акты выполненных работ"
        this.type = "certificateOfCompletedWorks"
        break;
      default:
        this.title = "Акты выполненных работ"
    }
    axios({
      method: "GET",
      url: "v1/documents/getByType?type=" + this.type,
      headers: {Authorization: `Bearer ` + localStorage.token},
    }).then(response => {
      this.isLoading = false
      this.documents = response.data.response
    })

  }
}
</script>

<style scoped>

</style>