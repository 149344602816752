<template>
  <div class="tariff-item-main" :style="getColor()">
    <img class="icon" :src="tariff.icon" alt="">
    <div class="title">{{ tariff.title }}</div>
  </div>


</template>

<script>
export default {
  name: "item-tariff-main",
  props: {
    tariff: {
      type: Object,
      default: () => {

      }
    }
  },
  methods: {
    getColor() {
      return 'background: ' + this.tariff.color + ';'
    },

  }
}
</script>

<style scoped>

.tariff-item-main {
  box-shadow: 0 0 16px rgba(43, 89, 120, 0.25);
  cursor: pointer;
  text-align: center;
  padding: 16px;
  margin-right: 12px;
  margin-bottom: 12px;
  border-radius: 16px;
}

.tariff-item-main:hover {
  cursor: pointer;
}

.tariff-item-main .icon {
  width: 56px;
  height: 56px;
}

.tariff-item-main .title {
  color: white;
  align-self: center;
  font-family: "Century Gothic", serif;
  font-weight: bold;
  text-align: center;
  font-size: 0.9rem;
}

</style>