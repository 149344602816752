<template>
  <page-scaffold>
    <card-content :is-loading="isLoading" :name="title" style="height: calc(100% - 24px)">
      <div class="content">
        <div class="data" style="position: relative" v-if="state!=='processing'">
          <div class="text" style="grid-area: title; font-size: 2.2rem; line-height: 2.2rem">{{ head }}</div>
          <div class="text" style="text-align:center; grid-area: spec; font-size: 1.1rem; line-height: 1.3rem; color: #5d5d5d">
            {{ description }}
          </div>
            <div class="social">
            <img class="img" :src="vk" @click="gotoVK()"/>
            <img class="img" :src="telegram" @click="gotoTelegram()"/>
            <img class="img" :src="whatsapp" @click="gotoWhatsapp()"/>
            <img class="img" :src="viber" @click="gotoViber()"/>
            <img class="img" :src="email" @click="gotoEmail()"/>
          </div>
          <div style="position: fixed; bottom: 0; margin-bottom: 32px">
            <div class="btn-container" @click="$router.push('/recomend-term')">
              <div class="btn-text">
                Условия программы
              </div>
            </div> 


            

          </div>
        </div>
        <img v-if="state!=='processing'" class="image" :src="icon"/>

      </div>



    </card-content>
  </page-scaffold>
</template>

<script>
import PageScaffold from "./page-scaffold";
import CardContent from "./card-content";
import axios from "axios";

export default {
  name: "page-recomend",
  components: {CardContent, PageScaffold},
  data: () => {
    return {
      account: Object,
      legalEntityData: Object,
      isQrCodeVisible: false,
      appNumber: String,
      isLoading: true,
      title: String,
      icon: require('@/assets/ic_ip_large.svg'),
      description: String,
      recommendedUrl: String,
      size: 200,
    }
  },
  methods:{
    gotoVK() {
      window.open("https://vk.com/share.php?url="+this.recommendedUrl+"&title=ИнТелКом");
    },
    gotoTelegram() {
      window.open("https://telegram.me/share/url?url="+this.recommendedUrl+"&text=ИнТелКом");
    },
    gotoViber() {
      window.open("viber://forward?text="+this.recommendedUrl);
    },
    gotoWhatsapp() {
      window.open("https://api.whatsapp.com/send?text="+this.recommendedUrl);
    },
    gotoEmail() {
      window.open("mailto:example@gmail.com?subject=ИнТелКом&body=Привет%20как%20ваши%20дела%3F"+this.recommendedUrl);
    },
    getAccount() {
      axios({
        method: "GET",
        url: "v2/account",
        headers: {Authorization: `Bearer ` + localStorage.token},
      }).then(response => {
        this.isLoading = false
        this.account = response.data.account
        this.recommendedUrl = response.data.account.recommendedUrl
        this.size = 200
        this.legalEntityData = response.data.account.legalEntityData
      })
    }
  },
  mounted() {
    this.isLoading = true
    this.getAccount()
    this.icon = require('@/assets/ic_referral.svg')
    this.vk = require('@/assets/ic_vk.svg')
    this.telegram = require('@/assets/ic_telegram.svg')
    this.viber = require('@/assets/ic_viber.svg')
    this.whatsapp = require('@/assets/ic_whatsapp.svg')
    this.email = require('@/assets/ic_email.svg')
    this.head = "С Вас рекомендация - с нас 1000 бонусов!"
    this.description = "Ваш друг получит скидку при подключении,\n Отправьте вашу персональную ссылку \n"
    this.title = "Рекомендация!"
    this.recommendedUrl = this.account.recommendedUrl

  }

}
</script>

<style scoped>

.title {
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #4d9ec4;
  justify-self: center;
  font-size: 1.5rem;
}

.date {
  margin-top: 16px;
  font-family: "Mont", serif;
  font-weight: bold;
  color: #858585;
  justify-self: center;
  font-size: 1.2rem;
}

.btnn {
  cursor: pointer;
  margin-top: 16px;
  border-radius: 10px;
  background: #58B1DA;
  color: white;
  justify-self: center;
  font-family: "Mont", serif;
  padding: 16px 24px 13px 24px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.content .data .text {
  margin-right: 32px;
  font-family: "Mont", serif;
  margin-top: 16px;
  margin-left: 16px;
  font-weight: bold;
  color: #111111;
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.content {
  width: 100%;
  height: calc(100% - 32px);
  display: grid;
  grid-template-columns: 1fr 1fr;

}
.content .social {
  align-self: center;
  justify-self: center;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.content .social .img {
  align-self: center;
  justify-self: center;
  min-height: 48px;
  margin-left: 10px;
  margin-right: 10px;
  width: 48px;
  max-height: 48;
}

.content .image {
  align-self: center;
  min-height: 50px;
  margin-left: 64px;
  margin-right: 64px;
  width: calc(100% - 128px);
  max-height: calc(100% - 256px);
}
.content .qr {
  align-self: center;
  min-height: 50px;
  margin-top: 64px;
  margin-left: 64px;
  margin-right: 64px;
  width: calc(100% - 128px);
  max-height: calc(100% - 256px);
}

.content .data {
  text-align: start;
  padding: 24px;
}

.content .btn {
  margin-top: 32px;
  cursor: pointer;
  border-radius: 16px;
  align-self: center;
  background: #58B1DA;
  color: white;
  font-family: "Mont", serif;
  padding: 16px 24px 16px 24px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
  width: 300px;
  margin-left: 16px;
  box-shadow: 0 0 16px rgba(88, 177, 218, 0.6);
}
.content .btn .btn-text {
  font-family: "Mont", serif;
  margin-top: 4px;
  font-weight: bold;
  color: white;
  font-size: 1.2rem;
  line-height: 1.2rem;
  align-self: center;
  justify-self: center;
}
.btn-container {
  cursor: pointer;
  box-shadow: 0 0 16px rgba(88, 177, 218, 0.6);
  height: 48px;
  margin-left: 16px;
  width: 300px;
  border-radius: 12px;
  padding: 0 48px;
  display: grid;
  background: #58B1DA;
}

.btn-container .btn-text {
  font-family: "Mont", serif;
  margin-top: 4px;
  font-weight: bold;
  color: white;
  font-size: 1.2rem;
  line-height: 1.2rem;
  align-self: center;
  justify-self: center;
}

</style>
