<template>
  <page-scaffold>
    <card-content :is-loading="isLoading" name="Умный дом" style="overflow-y: hidden">
      <div v-if="state!=='processing'"
           style="justify-content: center;  padding: 16px 0 100px 16px;display: grid; grid-template-columns: repeat(auto-fit, 185px)">
        <!--        <item-gadget :gadget="{name: 'Базовая станция', photos: []}" :is-base="true"/>-->
<!--        <item-base-station v-if="!isLoading"/>-->
        <item-gadget v-for="gadget in gadgets" :key="gadget.id"
                     :gadget="gadget" @addGadget="addGadget(gadget.id)" @removeGadget="removeGadget(gadget.id)"/>
      </div>
      <div v-if="state!=='processing'" class="widget-add" style="position: fixed">
        <div class="text" style="grid-area: itogo; align-self: center; font-size: 1rem; line-height: 1rem">Итого:</div>
        <div class="text" style="grid-area: price; margin-top: 4px; align-self: center;">{{ sum }} ₽</div>
        <div class="btn" style="grid-area: btn; align-self: center; cursor: pointer" @click="connect">Подключить</div>
      </div>

      <div v-if="state==='processing'"
           style="text-align: center; width: max-content; height: max-content; position: absolute; margin: auto; top: 0; left:0; right: 0; bottom: 0">
        <img :src="require('@/assets/ic_tariff_changing.svg')"/>
        <div class="title">Запрос в обработке</div>
        <div class="date">Номер заявки: {{ appNumber }}</div>
        <div class="btnn" @click="this.$router.push('/chat')">Служба поддержки</div>
      </div>

    </card-content>
  </page-scaffold>
</template>

<script>
import PageScaffold from "./page-scaffold";
import CardContent from "./card-content";
import ItemGadget from "./item-gadget";
import axios from "axios";

export default {
  name: "page-smart-house",
  components: { ItemGadget, CardContent, PageScaffold},
  data: () => {
    return {
      state: String,
      appNumber: String,
      isLoading: true,
      gadgets: [],
      sum: 0
    }
  },
  mounted() {
    this.isLoading = true
    axios({
      method: "GET",
      url: "v1/services/smartHome",
      headers: {Authorization: `Bearer ` + localStorage.token},
    }).then(response => {
      this.isLoading = false
      this.state = response.data.response.state
      if (this.state === 'processing')
        this.appNumber = response.data.response.data.applicationNumber

      this.gadgets = response.data.response.data.available
      this.gadgets.map(function (item) {
            item.quantity = 0
            item.price = item.prices[0]
          }
      )
    })
  },
  methods: {
    connect() {
      let self = this
      this.isLoading = true
      var devices = []
      this.gadgets.map(function (item) {
        if (item.quantity > 0) {
          devices.push({id: item.id, quantity: item.quantity})
        }
      })
      axios.post('v1/services/order/smartHome', {
        devices: devices,
      }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token
        },

      }).then(function (response) {
        self.appNumber = response.data.response.applicationNumber
        self.isLoading = false
        self.state = 'processing'
        self.gadgets.map(function (item) {
              item.quantity = 0
              item.price = item.prices[0]
            }
        )

      })
    },

    addGadget(id) {
      let self = this
      this.gadgets.map(function (item) {
        if (item.id === id) {
          item.quantity++
          self.changePrice(item)
        }
      })
    },
    changePrice(item) {
      if (item.quantity > 0)
        if (item.quantity > item.prices.length)
          item.price = item.prices[item.prices.length - 1]
        else
          item.price = item.prices[item.quantity - 1]
      else
        item.price = item.prices[0]
      this.updateSum()
    }
    ,

    updateSum() {
      let self = this
      this.sum = 0
      for (var i = 0; i < this.gadgets.length; i++) {
        self.sum += this.gadgets[i].quantity * this.gadgets[i].price
      }
    },

    removeGadget(id) {
      let self = this
      this.gadgets.map(function (item) {
        if (item.id === id) {
          if (item.quantity > 0) {
            item.quantity--
            self.changePrice(item)
          }
        }
      })
    }
  }
}
</script>

<style scoped>

.title {
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #4d9ec4;
  justify-self: center;
  font-size: 1.5rem;
}

.date {
  margin-top: 16px;
  font-family: "Mont", serif;
  font-weight: bold;
  color: #858585;
  justify-self: center;
  font-size: 1.2rem;
}

.btnn {
  cursor: pointer;
  margin-top: 16px;
  border-radius: 10px;
  background: #58B1DA;
  color: white;
  justify-self: center;
  font-family: "Mont", serif;
  padding: 16px 24px 13px 24px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.widget-add {
  z-index: 10;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 12px 16px;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-areas:
      "itogo btn"
      "price btn";
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 24px rgba(29, 132, 201, 0.6);
  width: 400px;
  background: #58B1DA;
  border-radius: 16px;
}

.widget-add .btn {
  border-radius: 10px;
  align-self: start;
  background: white;
  color: #4d9ec4;
  font-family: "Mont", serif;
  padding: 10px 24px 8px 24px;
  justify-self: end;
  margin-left: 16px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.widget-add .text {
  text-align: start;
  border-radius: 10px;
  align-self: center;
  color: white;
  font-family: "Mont", serif;
  margin-right: 16px;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.4rem;
}


</style>
