<template>
  <page-scaffold>
    <pop-up-message :message="message" v-if="isMessageVisible" v-on:close="isMessageVisible=false"/>
    <card-content :is-loading="isLoading" :name="title" style="height: calc(100% - 24px)">
      <div class="content">
        <div class="data" style="position: relative" v-if="state!=='processing'">
          <div class="text" style="grid-area: title; font-size: 2.2rem; line-height: 2.2rem">{{ head }}</div>
          <div class="text" style="text-align:left; grid-area: spec; font-size: 1.1rem; line-height: 1.3rem; color: #5d5d5d">
            {{ description }}
          </div>
          <input id="city" class="sum" type="text"  placeholder="Населенный пункт" v-model="city" required/>
          <input id="street" class="sum" type="text"  placeholder="Улица" v-model="street" required/>
          <input id="appart" class="sum" type="text"  placeholder="Номер дома" v-model="appart" required/>
          <input id="phone" class="sum" type="text" required  placeholder="Телефон" v-model="phone" />
          <input id="name" class="sum" type="text" required placeholder="ФИО" v-model="name" />
          <input id="email" class="sum" type="email"  placeholder="Email" v-model="email" required/>
          
          <div  class="btn" @click="sendForm">
            <div class="btn-text">
              Отправить заявку
            </div>
          </div>
          <div class="btn" @click="$router.push('/recomendation_doc.pdf')">
              <div class="btn-text">
                Условия программы
              </div>
          </div>
        </div>
        <img v-if="state!=='processing'" class="image" :src="icon"/>
      </div>
    </card-content>
  </page-scaffold>
</template>

<script>
import PageScaffold from "./page-scaffold";
import CardContent from "./card-content";
import PopUpMessage from "./pop-up-message";
import axios from "axios";

export default {
  name: "page-recomend",
  components: {CardContent, PageScaffold, PopUpMessage},
  data: () => {
    return {
      account: Object,
      legalEntityData: Object,
      appNumber: String,
      isLoading: false,
      isMessageVisible: false,
      title: String,
      icon: require('@/assets/ic_referral.svg'),
      description: String,
      recommendedUrl: String,
      size: 200,
    }
  },
  methods:{
    sendForm() {
      this.isLoading = true
        if (this.phone !== ""){
        if (this.name !== ""){
        if (this.city !== ""){
        if (this.appart !== ""){
        if (this.email !== ""){
        if (this.street !== ""){
            axios({
              method: "PUT",
              params: {
                city: this.city,
                street: this.street,
                appart: this.appart,
                phone: this.phone,
                name: this.name,
                email: this.email,
                refid: this.$route.query.uid
              },
              url: "/connectrequest",
            }).then(response => {
        console.log(response)
          this.isLoading = false
          this.message = "Заявка успешно отправлена!";
          this.isMessageVisible = true
      }) 
      }else {
            this.isLoading = false
            this.message = "Укажите Улицу"
            this.isMessageVisible = true
          }
        }else {
          this.isLoading = false
            this.message = "Укажите Ваш Email"
            this.isMessageVisible = true
          }
        }else {
          this.isLoading = false
            this.message = "Укажите номер дома"
            this.isMessageVisible = true
          }
        }else {
          this.isLoading = false
          this.message = "Укажите город"
          this.isMessageVisible = true
        }
      }else {
        this.isLoading = false
          this.message = "Укажите Ваше имя"
          this.isMessageVisible = true
        }
      }else {
        this.isLoading = false
          this.message = "Укажите Ваш номер телефона"
          this.isMessageVisible = true
        }
      }
    },
  
  mounted() {
    this.icon = require('@/assets/ic_referral.svg')
    this.head = "Рекомендация. Заявка на подключение"
    this.description = "Вы получите скидку, если воспользуейтесь этой рекомендацией"
    this.title = ""


  }

}
</script>

<style scoped>


.title {
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #4d9ec4;
  justify-self: left;
  font-size: 1.5rem;
}

.date {
  margin-top: 16px;
  font-family: "Mont", serif;
  font-weight: bold;
  color: #858585;
  justify-self: left;
  font-size: 1.2rem;
}
.sum {
  width: calc(100% - 100px);
  font-family: "Mont", serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: black;
  padding-top: 10px;
  padding-bottom: 8px;
  margin-top: 10px;
  margin-left: 24px;
  margin-right: 24px;
  text-align: center;
  border-width: 0;
  outline: none;
  height: 32px;
  background: #cccc;
  border-radius: 16px;
}

.btnn {
  cursor: pointer;
  margin-top: 16px;
  border-radius: 10px;
  background: #58B1DA;
  color: white;
  justify-self: center;
  font-family: "Mont", serif;
  padding: 16px 24px 13px 24px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.content .data .text {
  margin-right: 32px;
  font-family: "Mont", serif;
  margin-top: 16px;
  margin-left: 16px;
  font-weight: bold;
  color: #111111;
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.content {
  width: 100%;
  height: calc(100% - 32px);
  display: grid;
  grid-template-columns: 1fr 1fr;

}

.content .image {
  align-self: center;
  min-height: 50px;
  margin-left: 64px;
  margin-right: 64px;
  width: calc(100% - 128px);
  max-height: calc(100% - 256px);
}
.content .qr {
  align-self: center;
  min-height: 50px;
  margin-top: 64px;
  margin-left: 64px;
  margin-right: 64px;
  width: calc(100% - 128px);
  max-height: calc(100% - 256px);
}

.content .data {
  text-align: start;
  padding: 24px;
}

.content .btn {
  margin-top: 32px;
  cursor: pointer;
  border-radius: 16px;
  align-self: center;
  background: #58B1DA;
  color: white;
  font-family: "Mont", serif;
  padding: 16px 24px 16px 24px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
  width: calc(100% - 100px);
  margin-left: 16px;
  box-shadow: 0 0 16px rgba(88, 177, 218, 0.6);
}
.content .btn .btn-text {
  font-family: "Mont", serif;
  margin-top: 4px;
  font-weight: bold;
  color: white;
  font-size: 1.2rem;
  line-height: 1.2rem;
  align-self: center;
  justify-self: center;
}

.btn-container {
  cursor: pointer;
  box-shadow: 0 0 16px rgba(88, 177, 218, 0.6);
  height: 48px;
  margin-left: 16px;
  width: 300px;
  border-radius: 12px;
  padding: 0 48px;
  display: grid;
  background: #58B1DA;
}

.btn-container .btn-text {
  font-family: "Mont", serif;
  margin-top: 4px;
  font-weight: bold;
  color: white;
  font-size: 1.2rem;
  line-height: 1.2rem;
  align-self: center;
  justify-self: center;
}

</style>
