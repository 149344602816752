<template>
  <div class="card-container">
    <div class="title">На данный момент нет<br/>доступных тарифов</div>
  </div>

</template>

<script>
export default {
  name: "EmptyListMessage",

}
</script>

<style scoped>


.card-container {
  width: max-content;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  overflow-y: hidden;
  height: max-content;
  border-radius: 24px;
}

.card-container .title {
  margin-top: 24px;
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #53a6cc;
  grid-area: title;
  align-self: center;
  justify-self: center;
  font-size: 1.5rem;
}


.card-container .card-content {
  grid-area: content;
}


</style>