<template>
  <router-view/>
</template>

<style>
@font-face {
  font-family: "Century Gothic";
  src: local("MyFont"),
  url(./assets/fonts/CenturyGothic-Bold.woff2) format("woff");
}

@font-face {
  font-family: "Mont";
  src: local("Mont"),
  url(./assets/fonts/Mont-SemiBold.woff2) format("woff");
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #9d9d9d;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #9d9d9d;
  opacity: 1;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #9d9d9d;
  opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #9d9d9d;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #9d9d9d;
}

::placeholder { /* Most modern browsers support this now. */
  color: #9d9d9d;
}
</style>
