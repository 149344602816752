<template>
  <div class="widget-tariffs">
    <div class="title">Подключенные:</div>
    <div class="widget-my-tariffs" @click="$router.push('my-tariffs')">
      <div class="icons">
        <img v-if="isNet" class="icon" :src="require('@/assets/ic_internet_blue.svg')" alt="">
        <img v-if="isTV" class="icon" :src="require('@/assets/ic_tv_blue.svg')" alt="">
        <img v-if="services.videoSurveillance==='active'||services.videoSurveillance==='processing'" class="icon"
             :src="require('@/assets/ic_camera_blue.svg')" alt="" :style="getOpacity(0)">
        <img v-if="services.staticIP==='active'||services.staticIP==='processing'" class="icon"
             :src="require('@/assets/ic_ip_blue.svg')" alt="" :style="getOpacity(1)">
        <img v-if="services.smartHome==='active'||services.smartHome==='processing'" class="icon"
             :src="require('@/assets/ic_house_blue.svg')" alt="" :style="getOpacity(2)">
      </div>
      <div class="price">{{ totalCost + " ₽" }}</div>
      <div class="date">{{ "Списание " + nextPaymentDate }}</div>
    </div>
    <div class="title">Все услуги:</div>
    <div class="tariffs-buttons">
      <item-tariff-main v-for="tariff in isLegal?legalEntityTariffs:tariffs" v-bind:key="tariff.title" :tariff="tariff"
                        @click="$router.push(tariff.route)"/>
    </div>
  </div>
</template>

<script>

import ItemTariffMain from "./item-tariff-main";

export default {
  name: "widget-tariffs-main",
  components: {ItemTariffMain},
  props: {
    isLegal: {
      type: Boolean,
      default: false
    },
    services: {
      type: Object,
      default: () => ({
        staticIP: "notActive",
        videoSurveillance: "notActive",
        smartHome: "notActive"
      })
    },
    isTV: Boolean,
    isNet: Boolean,
    totalCost: {
      type: String,
      default: "0"
    },
    nextPaymentDate: {
      type: String,
      default: ""
    },
    components: {
      ItemTariffMain
    }
  }

  ,
  data() {
    return {
      legalEntityTariffs: [
        {
          title: "Статический IP",
          icon: require('@/assets/ic_ip.svg'),
          color: "#3E39CC",
          legalAccess: true,
          route: "/service?type=staticIP"
        },
        {
          title: "Видеонаблюдение",
          legalAccess: true,
          icon: require('@/assets/ic_camera.svg'),
          color: "#3498DB",
          route: "/camera"
        },
        {
          title: "SIM-Карты",
          icon: require('@/assets/ic_sim.svg'),
          color: "rgba(26,188,156,0.35)",
          legalAccess: true,
          route: ""
        },
        {
          title: "Другие опции",
          legalAccess: true,
          icon: require('@/assets/ic_another_options.svg'),
          color: "rgba(110,110,133,0.37)",
          route: ""
        },
      ],
      tariffs: [
        {
          title: "Интернет",
          icon: require('@/assets/ic_internet.svg'),
          color: "#80D864",
          legalAccess: false,
          route: "/tariffs?type=internet",
        },
        {
          title: "Телевидение",
          icon: require('@/assets/ic_tv.svg'),
          color: "#D34B7F",
          legalAccess: false,
          route: "/tariffs?type=tv",
        },
        {
          title: "Статический IP",
          icon: require('@/assets/ic_ip.svg'),
          color: "#3E39CC",
          legalAccess: true,
          route: "/service?type=staticIP"
        },
        {
          title: "Видеонаблюдение",
          legalAccess: true,
          icon: require('@/assets/ic_camera.svg'),
          color: "#3498DB",
          route: "/camera"
        },
        {
          title: "Умный дом",
          legalAccess: false,
          icon: require('@/assets/ic_smart_house.svg'),
          color: "#9B59B6",
          route: "smart-house"
        },
        {
          title: "SIM-Карты",
          icon: require('@/assets/ic_sim.svg'),
          color: "rgba(26,188,156,0.35)",
          legalAccess: true,
          route: ""
        },
        {
          title: "Опция \"В отпуске\"",
          legalAccess: false,
          icon: require('@/assets/ic_holiday.svg'),
          color: "#ED872D",
          route: "/service?type=vacation"
        },
        {
          title: "Другие опции",
          legalAccess: true,
          icon: require('@/assets/ic_another_options.svg'),
          color: "rgba(110,110,133,0.37)",
          route: ""
        },
      ]
    }
  }, mounted() {
  },
  methods: {
    getOpacity(type) {
      var opacity
      switch (type) {
        case 0:
          opacity = this.services.videoSurveillance === 'processing' ? '50%' : '100%'
          break;
        case 1:
          opacity = this.services.staticIP === 'processing' ? '50%' : '100%'
          break;
        case 2:
          opacity = this.services.smartHome === 'processing' ? '50%' : '100%'
          break;
      }
      var op = 'opacity: ' + opacity
      return op
    }
  }
}
</script>

<style scoped>

.widget-tariffs {
  padding: 24px 12px 24px 24px;
  text-align: start;
  /*margin-bottom: 24px;*/
  margin-right: 24px;
  background: white;
  width: calc(100% - 24px * 2 - 12px);
  /*height: calc(100% - 3 * 24px);*/
  height: max-content;
  box-shadow: 0 0 24px rgba(43, 89, 120, 0.15);
  border-radius: 24px;
}

.widget-my-tariffs {
  cursor: pointer;
  width: 100%;
  min-width: 300px;
  max-width: max-content;
  padding: 16px 48px 16px 16px;
  margin-bottom: 24px;
  margin-right: 24px;
  background: white;
  border-radius: 24px;
  border-style: solid;
  border-width: 3px;
  border-color: #58B1DA;
}

.widget-my-tariffs .icons {
  height: 48px;
  display: inline-block;
  margin-bottom: 16px;
}

.widget-my-tariffs .icons .icon {
  fill-opacity: 100%;
  height: 48px;
  margin-right: 16px;
  display: inline-block;
}


.date {
  margin-top: 4px;
  font-family: "Mont", serif;
  font-weight: bolder;
  font-size: 0.95rem;
  line-height: 0.95rem;
  color: #58B1DA;
  justify-self: start;
  grid-area: title;
}

.price {
  font-family: "Mont", serif;
  font-weight: bold;
  line-height: 1.8rem;
  color: #58B1DA;
  justify-self: start;
  font-size: 1.8rem;
  grid-area: title;
}

.tariffs-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: max-content max-content max-content;
}

.title {
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #2D666E;
  grid-area: title;
  justify-self: start;
  font-size: 1.2rem;
  margin-top: 8px;
  margin-bottom: 16px;
}

</style>