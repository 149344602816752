<template>
  <page-scaffold>
    <card-content :is-loading="isLoading" :name="title" style="height: calc(100% - 24px)">
      <div class="content">
        <div class="data" style="position: relative" v-if="state!=='processing'">
          <div class="text" style="grid-area: title; font-size: 2.2rem; line-height: 2.2rem">{{ title }}</div>
          <div class="text" style="grid-area: price; font-size: 1.8rem; line-height: 1.8rem">{{ price }}</div>
          <div class="text" style="grid-area: spec-title; font-size: 1.2rem; line-height: 1.2rem">Характеристики:</div>
          <div class="text" style="text-align:justify; grid-area: spec; font-size: 1.1rem; line-height: 1.3rem; color: #5d5d5d">
            {{ description }}
          </div>

          <div style="position: fixed; bottom: 0; margin-bottom: 32px">
            <div v-if="state==='active'" class="text"
                 style="grid-area: price; font-size: 1.8rem; line-height: 1.8rem; color: #49b97a">Услуга успешно<br/>подключена!
            </div>
            <div v-if="state==='active'" style="margin-left: 16px; margin-top: 8px; color: #5d5d5d">
              Cвяжитесь со службой поддержки <br/> для уточнения деталей
            </div>
            <div class="btn-container" v-if="state==='notActive'" @click="connect">
              <div class="btn-text">
                Подключить
              </div>
            </div>

            <div v-if="state==='unavailable'" class="btn-container"
                 style="background: #c4c4c4; box-shadow: 0 0 0px rgba(88, 177, 218, 0); cursor: default">
              <div class="btn-text">
                Подключить
              </div>
            </div>

            <div v-if="state==='unavailable'"
                 style="margin-left: 64px; text-align: center; width: 300px; margin-top: 8px; color: #5d5d5d">Опция
              недостпуна на вашем тарифе, свяжитесь со службой поддержки для уточнения деталей
            </div>

          </div>
        </div>
        <img v-if="state!=='processing'" class="image" :src="icon"/>

      </div>

      <div v-if="state==='processing'"
           style="text-align: center; width: max-content; height: max-content; position: absolute; margin: auto; top: 0; left:0; right: 0; bottom: 0">
        <img :src="require('@/assets/ic_tariff_changing.svg')"/>
        <div class="title">Запрос в обработке</div>
        <div class="date">Номер заявки: {{appNumber}}</div>
        <div class="btnn" @click="this.$router.push('/chat')">Служба поддержки</div>
      </div>

    </card-content>
  </page-scaffold>
</template>

<script>
import PageScaffold from "./page-scaffold";
import CardContent from "./card-content";
import axios from "axios";

export default {
  name: "page-service",
  components: {CardContent, PageScaffold},
  data: () => {
    return {
      appNumber: String,
      isLoading: true,
      title: String,
      orderNumber: String,
      state: String,
      price: "0 ₽/меc",
      icon: require('@/assets/ic_ip_large.svg'),
      description: String,
    }
  },
  mounted() {
    var url
    switch (this.$route.query.type) {
      case "staticIP":
        url = "v1/services/staticIP"
        this.icon = require('@/assets/ic_ip_large.svg')
        this.description = "Статический IP адрес - это адрес устройства в сети, назначенный на постоянной основе. Он не изменяется с течением времени и всегда идентифицирует то устройство, с которого происходит выход в сеть. С помощью статического IP адреса пользователь может получить доступ к своему устройству с любого компьютера, находящегося в любой точке земного шара, поскольку после подключения этой услуги \"Интелком\" абоненту выделяется определенный уникальный IP-адрес, действующий в глобальной сети интернет."
        this.title = "Статический IP"
        break;
      case "vacation":
        this.icon = require('@/assets/ic_vacation_large.svg')
        url = "v1/services/vacation"
        this.description = "Если вы собираетесь уезжать в отпуск, вы можете приостановить действие вашего тарифа на один месяц. Интернет остается активным, но ограничивается до 10 мбит/с.\n" +
            "Данная опция доступна только на верхних тарифах, абонентам подключенным не ранее 12 месячного периода. Данная услуга позволяет при существенной экономии не отключать интернет и продолжать использовать видеонаблюдение или умный дом. \n"
        this.title = "В отпуске"
        break;
      case "recomendation":
        this.icon = require('@/assets/ic_vacation_large.svg')
        url = "v1/services/recomend"
        this.description = "С Вас рекомендация - с нас 1000 бонусов!!!\n" +
            "Ваш друг получит 500Р скидки при подключении, отправьте вашу персональную ссылку \n"
        this.title = "Рекомендация"
        break;
    }
    this.isLoading = true

    axios({
      method: "GET",
      url: url,
      headers: {Authorization: `Bearer ` + localStorage.token},
    }).then(response => {
      this.isLoading = false
      if (this.title === "В отпуске")
        this.price = "Бесплатно"
      else
        this.price = response.data.response.data.pricePerMonth + " ₽/мес"

      this.state = response.data.response.state
      if (this.state === 'processing')
        this.appNumber = response.data.response.data.applicationNumber
    })

  },
  methods: {
    connect() {
      this.isLoading = true
      var url
      var url2
      if (this.title !== "В отпуске") {
        url = "v1/services/order/staticIP"
        url2 = "v1/services/staticIP"
      } else {
        url = "v1/services/order/vacation"
        url2 = "v1/services/vacation"
      }
      axios({
        method: "POST",
        url: url,
        headers: {Authorization: `Bearer ` + localStorage.token},
      }).then(response => {
        console.log(response)
        axios({
          method: "GET",
          url: url2,
          headers: {Authorization: `Bearer ` + localStorage.token},
        }).then(response2 => {
          if (this.title === "В отпуске")
            this.price = "Бесплатно"
          else
            this.price = response2.data.response.data.pricePerMonth + " ₽/мес"

          this.state = response2.data.response.state
          if (this.state === 'processing')
            this.appNumber = response2.data.response.data.applicationNumber
          this.isLoading = false

        })

      })
    }
  }
}
</script>

<style scoped>

.title {
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #4d9ec4;
  justify-self: center;
  font-size: 1.5rem;
}

.date {
  margin-top: 16px;
  font-family: "Mont", serif;
  font-weight: bold;
  color: #858585;
  justify-self: center;
  font-size: 1.2rem;
}

.btnn {
  cursor: pointer;
  margin-top: 16px;
  border-radius: 10px;
  background: #58B1DA;
  color: white;
  justify-self: center;
  font-family: "Mont", serif;
  padding: 16px 24px 13px 24px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.content .data .text {
  margin-right: 32px;
  font-family: "Mont", serif;
  margin-top: 16px;
  margin-left: 16px;
  font-weight: bold;
  color: #111111;
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.content {
  width: 100%;
  height: calc(100% - 32px);
  display: grid;
  grid-template-columns: 1fr 1fr;

}

.content .image {
  align-self: center;
  min-height: 50px;
  margin-left: 64px;
  margin-right: 64px;
  width: calc(100% - 128px);
  max-height: calc(100% - 256px);
}

.content .data {
  text-align: start;
  padding: 24px;
}

.btn-container {
  cursor: pointer;
  box-shadow: 0 0 16px rgba(88, 177, 218, 0.6);
  height: 48px;
  margin-left: 16px;
  width: 300px;
  border-radius: 12px;
  padding: 0 48px;
  display: grid;
  background: #58B1DA;
}

.btn-container .btn-text {
  font-family: "Mont", serif;
  margin-top: 4px;
  font-weight: bold;
  color: white;
  font-size: 1.2rem;
  line-height: 1.2rem;
  align-self: center;
  justify-self: center;
}

</style>