<template>
  <div class="outlined-button">
    <img class="icon" :src="icon" alt="">
    <div class="title">{{ title }}</div>
  </div>

</template>

<script>
export default {
  name: "outlined-button-with-icon",

  props: {
    icon: {
      type: String,
      default: () => "require('@/assets/ic_card.svg')"
    },
    title: {
      type: String,
      default: () => ''
    },
  }
}
</script>

<style scoped>

.outlined-button {
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 40px;
  width: calc(100% - 24px);
  height: 40px;
  display: grid;
  padding-left: 16px;
  border-style: solid;
  border-width: 2px;
  border-color: #58B1DA;
  grid-area: account;
  align-self: center;
  grid-template-columns: 40px max-content;
  grid-template-rows: auto;
}

.outlined-button .icon {
  max-height: 24px;
  max-width: 24px;
  align-self: center;
}

.outlined-button .title {
  overflow-x: hidden;
  align-self: center;
  margin-right: 24px;
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #58B1DA;
  justify-self: start;
  font-size: 1.1rem;
}

</style>