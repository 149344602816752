<template>
  <div class="notification-item">
    <img :src="require('@/assets/ic_notification_bell.svg')" class="icon" alt="">
    <div class="date">{{convertTime(notification.time)}}</div>
    <div class="text">{{notification.message}}</div>
    <div class="title">{{notification.title}}</div>
  </div>

</template>

<script>
export default {
  name: "item-notification",
  props: {
    notification: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    convertTime(time) {
      const date = new Date(time * 1000);
      const day = "0"+ date.getDate();
      const month = "0"+ (date.getMonth()+1);
      const year =  date.getFullYear();
      const hours = "0" + date.getHours();
      const minutes = "0" + date.getMinutes();

      return day.substr(-2)+ '.' + month.substr(-2) + '.' + year + ' в ' + hours.substr(-2) + ':' + minutes.substr(-2);

    },
  }
}
</script>

<style scoped>

.notification-item {
  text-align: start;
  padding: 8px;
  background: white;
  border-radius: 24px;
  height: max-content;
  box-shadow: 0 0 16px rgba(41, 48, 67, 0.15);
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: max-content max-content max-content;
  grid-template-areas:
      "icon title"
        "icon date"
      "icon text"
      ;
}

.notification-item .title {
  grid-area: title;
  font-family: "Mont", serif;
  margin-top: 12px;
  margin-left: 16px;
  font-weight: bold;
  color: #2D666E;
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.notification-item .text {
  grid-area: text;
  margin-top: 4px;
  margin-left: 16px;
  max-width: 100%;
  white-space: normal;
  color: #6f8183;
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 8px;
}

.notification-item .date {
  margin-top: 4px;
  grid-area: date;
  font-family: "Mont", serif;
  margin-left: 16px;
  font-weight: bold;
  color: #2D666E;
  align-self: start;
  font-size: 1rem;
  line-height: 1rem;
}

.notification-item .icon {
  grid-area: icon;
  display: inline-block;
  margin-top: 8px;
  vertical-align: bottom;
  margin-bottom: 8px;
  margin-left: 16px;
  height: 48px;
  width: 48px;
}


</style>
