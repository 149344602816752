<template>
  <div class="background" :style="'background: rgba(255, 255, 255, '+opacity+')'">
    <div class="card-container">
      <div class="title">{{ title }}</div>
      <div class="card-content">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pop-up-loading",
  props: {
    title: {
      type: String,
      default: "Загрузка"
    },
    opacity: {
      type: Number,
      default: 0.5
    }
  },
  methods: {
    closePopUp() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

.background {
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  z-index: 4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-container {
  width: max-content;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  overflow-y: hidden;
  height: max-content;
  box-shadow: 0 0 32px rgba(195, 202, 206, 0.5);
  border-radius: 24px;
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-template-rows: max-content 1px max-content;
  grid-template-areas:
      "back title"
      "divider divider"
      "content content";
}

.card-container .title {
  margin-top: 24px;
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #53a6cc;
  grid-area: title;
  align-self: center;
  justify-self: center;
  margin-right: 64px;
  font-size: 1.5rem;
}

.card-container .divider {
  background: #e7e7e7;
  height: 1px;
  grid-area: divider;
  width: 100%;
}

.card-container .card-content {
  grid-area: content;
}

.btn {
  cursor: pointer;
  border-radius: 10px;
  align-self: center;
  background: #58B1DA;
  color: white;
  font-family: "Mont", serif;
  padding: 10px 24px 8px 24px;
  margin: 16px 16px 16px 16px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}


.lds-ring {
  margin-top: 32px;
  margin-bottom: 48px;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #58B1DA;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #58B1DA transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>