<template>
  <div class="widget-balance">
    <div class="top">
      <img :src="require('@/assets/ic_contract_number_white.svg')" style="height: 56px" alt="">
      <div v-if="isPhys" class="title">Лицевой счет №</div>
      <div v-if="isPhys" class="number" style="letter-spacing: 0.4rem;">{{ number === "" ? '- - - - - - -': number }}</div>
    </div>

    <div class="title" style="color: #949494">Текущий баланс:</div>
    <div class="number"
         style="color: #24484e; font-size: 3rem; line-height: 3rem; margin-bottom: 24px; margin-top: 16px">
      {{ balance }} ₽
    </div>

    <div class="title" style="color: #949494">Рекомендуемый платеж: {{ recommendPay }} ₽</div>


    <div class="number"
         style="color: #24484e; font-size: 3rem; line-height: 3rem; margin-bottom: 24px; margin-top: 16px">
      {{ bonus }} Б
    </div>

    <div class="btn" @click="this.$emit('update')">Обновить</div>

    <div class="title" style="color: #58B1DA; cursor: pointer" @click="this.$router.push('/balance')">История платежей</div>
    <div class="title" style="color: #58B1DA; cursor: pointer" @click="this.$router.push('/bonus')">История бонусов</div>
  </div>
</template>

<script>
export default {
  name: "widget-balance-main",
  props: {
    isPhys: Boolean,
    number: {
      type: String,
      default: "- - - - - - -"
    },
    balance: {
      type: String,
      default: "-"
    },
    recommendPay: {
      type: String,
      default: "-"
    },
    bonus: {
      type: String,
      default: "-"
    },
  }
}
</script>

<style scoped>

.widget-balance {
  text-align: center;
  margin-right: 24px;
  background: white;
  width: calc(100% - 24px);
  padding-bottom: 24px;
  box-shadow: 0 0 24px rgba(43, 89, 120, 0.15);
  border-radius: 24px;
}

.widget-balance .top {
  padding: 16px;
  text-align: center;
  background: #58B1DA;
  border-radius: 24px 24px 0 0;

}

.number {
  font-family: "Mont", serif;
  font-weight: bold;
  margin-top: 8px;
  line-height: 1.5rem;
  color: #ffffff;
  font-size: 1.6rem;
}

.btn {
  cursor: pointer;
  padding: 16px 14px 14px 14px;
  background: #58B1DA;
  margin-right: 24px;
  margin-left: 24px;
  border-radius: 16px;
  box-shadow: 0 0 24px rgba(0, 153, 255, 0.3);
  width: calc(100% - 48px - 28px);
  color: white;
  font-family: "Mont", serif;
  font-weight: bolder;
  margin-top: 16px;
  line-height: 1rem;
  font-size: 1rem;
}

.btn:hover {
  box-shadow: 0 0 24px rgba(0, 153, 255, 0.6);

}

.title {
  font-family: "Mont", serif;
  font-weight: bolder;
  margin-top: 16px;
  line-height: 1rem;
  color: #ffffff;
  font-size: 1rem;
}


</style>
