<template>
  <page-scaffold>
    <card-content :is-loading="isLoading" name="Уведомления" style="overflow-y: hidden">
      <div style="padding: 24px">
        <item-notification v-for="notification in notifications"
                           :key="notification.id"
                           :notification="notification"/>

      </div>
    </card-content>
  </page-scaffold>

</template>

<script>
import PageScaffold from "./page-scaffold";
import CardContent from "./card-content";
import ItemNotification from "./item-notification";
import axios from "axios";

export default {
  name: "page-notifications",
  data: () => {
    return {
      isLoading: true,
      notifications: []
    }
  },
  components: {ItemNotification, CardContent, PageScaffold},
  mounted() {
    this.isLoading = true
    axios({
      method: "GET",
      url: "v1/notifications",
      headers: {Authorization: `Bearer ` + localStorage.token},
    }).then(response => {
      this.isLoading = false
      this.notifications = response.data
    })
  }
}
</script>

<style scoped>


</style>