<template>
  <div class="top-bar">
    <img class="logo" @click="$router.push('/')" :src="require('@/assets/ic_logo.svg')" alt="">
    <div class="title" @click="$router.push('/')">ИнТелКом</div>
    <div class="btn-recomend">
      <img class="recomend" @click="$router.push('/recomend')" alt="">
      <div class="name" @click="$router.push('/recomend')">РЕКОМЕНДОВАТЬ</div>
    </div>
    <div class="btn-account">
      <img class="account" @click="$router.push('/profile')" :src="require('@/assets/ic_account_circle.svg')" alt="">
      <div class="name" @click="$router.push('/profile')">{{ name }}</div>
      <img class="log-out" @click="clearToken" :src="require('@/assets/ic_logout.svg')" alt="">
    </div>
  </div>

</template>

<script>
export default {
  name: "top-bar",
  props: {
    name: {
      type: String,
      default: "Имя Пользователя"
    }
  },
  methods: {
    clearToken() {
      localStorage.token = ""
      localStorage.userID = ""
      localStorage.mainCss = "main-page-phys"
      localStorage.userName = "Имя пользователя"
      localStorage.userType = "individual"

      this.$router.push("/login")
    }
  },
}
</script>

<style scoped>

.top-bar {
  padding: 16px;
  display: grid;
  width: calc(100% - 32px);
  vertical-align: center;
  grid-template-columns: 64px auto 1fr 300px max-content;
  grid-template-areas: "logo title null points account";
}

.top-bar .logo {
  cursor: pointer;
  grid-area: logo;
  max-height: 40px;
  align-self: center;
}

.top-bar .title {
  cursor: pointer;
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #58B1DA;
  margin-left: 16px;
  font-size: 1.8rem;
  align-self: center;
  grid-area: title;
}

.top-bar .points {
  grid-area: points;
  margin-right: 32px;
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #58B1DA;
  justify-self: end;
  margin-left: 16px;
  font-size: 1.2rem;
  align-self: center;

}

.top-bar .btn-account {
  border-radius: 32px;
  height: 32px;
  display: grid;
  padding: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: #58B1DA;
  grid-area: account;
  align-self: center;
  grid-template-columns: 36px 1fr 32px;
  grid-template-rows: auto;
}

.top-bar .btn-account .account {
  cursor: pointer;
  max-height: 32px;
  max-width: 32px;
  align-self: center;
}

.top-bar .btn-account .name {
  cursor: pointer;
  align-self: center;
  margin: 0 16px;
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #58B1DA;
  justify-self: end;
  font-size: 1.1rem;
}

.top-bar .btn-recomend {
  margin-right: 34px;
  border-radius: 32px;
  height: 32px;
  display: grid;
  padding: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: #58B1DA;
  grid-area: points;
  align-self: center;
  grid-template-columns: 36px 1fr 32px;
  grid-template-rows: auto;
}

.top-bar .btn-recomend .recomend {
  cursor: pointer;
  max-height: 32px;
  max-width: 32px;
  align-self: center;
}

.top-bar .btn-recomend .name {
  cursor: pointer;
  align-self: center;
  margin: 0 16px;
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #58B1DA;
  justify-self: end;
  font-size: 1.1rem;
}



.top-bar .btn-account .log-out {
  cursor: pointer;
  max-height: 30px;
  max-width: 30px;
  align-self: center;
}

</style>
