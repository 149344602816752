<template>
  <pop-up-message :message="message" v-if="isMessageVisible" v-on:close="isMessageVisible=false"/>
  <page-scaffold>
    <card-content :is-loading="isLoading" :name="'Как потратить бонусы?'" style="position: relative">
      <div id="cont" style="height: calc(100% - 64px); padding: 32px 64px; display: flex; overflow-x: hidden; position: relative">
        <div :style="{'margin-left': '-' + (400*currentIndex) + 'px'}" style="width: 0; transition: all ease 750ms"/>
        <div class="card" style="transition: all ease 750ms;">
    <div class="text1" style="margin-top: 8px">Оплатить свой тариф</div>
    <div class="text1" style="margin-top: 8px; color: #2F7393; font-size: 1.3rem">Экономьте до 50% вашего тарифа!</div>
    <div class="text" style="margin-top: 4px; margin-bottom: 4px">Вам доступно: {{ account.bonus }} бонусов </div>
    <div class="text1" style="font-size: 1.3rem; margin-top: 16px; margin-bottom: 5 px">Сколько хотели бы списать?</div>
        <input id="amountTariff" class="sum" type="text" onkeyup="this.value = this.value.replace(/[^\d]/g,'');" placeholder="Сумма списания" v-model="amountTariff"/>
    <div class="btn-container" @click="bonusTariff">
      <div class="text" >
        Хочу потратить
      </div>
    </div>
  </div>
  <div class="card" style="transition: all ease 750ms;">
    <div class="text1" style="margin-top: 8px">Оплатить доп. услугу</div>
    <div class="text1" style="margin-top: 8px; color: #2F7393; font-size: 1.3rem">Подключайте новые услуги и платите бонусами!</div>
    <div class="text" style="margin-top: 4px; margin-bottom: 4px">Вам доступно: {{ account.bonus }} бонусов</div>
    <div class="text1" style="font-size: 1.3rem; margin-top: 16px; margin-bottom: 5px">Выберите доп. услугу</div>
        <select class="sum" v-model="serviceID" @change="onChange($event)" style="font-size: 1.3rem; height: 49px; color: #ffffff;"> 
          <option disabled value="">Выбрать услугу</option>
  <option value="6">Замена/продажа IPTV приставки - 3500.00 руб.</option>
  <option value="7">Замена/продажа Wi-Fi роутера - 3500.00 руб.</option>
  <option value="40">Продажа уличной видеокамеры - 6000.00 руб.</option>
</select>
    <input class="sum" disabled type="text" onkeyup="this.value = this.value.replace(/[^\d]/g,'');" placeholder="Сумма списания" v-model="amountService"/>
    <div class="btn-container"  @click="bonusAddService">
      <div class="text" >
        Хочу потратить
      </div>
    </div>
  </div>
  <div class="card" style="transition: all ease 750ms;">
    
    <div class="text1" style="margin-top: 8px">Подарить другу</div>
    <div class="text1" style="margin-top: 8px; color: #2F7393; font-size: 1.3rem">Сюрприз для друзей: Радуйте близких бонусами!</div>
    <div class="text" style="margin-top: 4px; margin-bottom: 4px">Вам доступно: {{ account.bonus }} бонусов</div>
    <div class="text1" style="font-size: 1.3rem; margin-top: 16px; margin-bottom: 5px">Сколько хотели бы списать?</div>
    <input class="sum"  type="text"  onkeyup="this.value = this.value.replace(/[^\d]/g,'');" placeholder="Номер договора" v-model="touserID"/>
    <input class="sum"  type="text" onkeyup="this.value = this.value.replace(/[^\d]/g,'');" placeholder="Сумма списания" v-model="amountTransfer"/>
    <div class="btn-container"  @click="bonusTransfer">
      <div class="text" >
        Хочу потратить
      </div>
    </div>
  
  </div>

    
        <img v-if="!isLoading" class="btn-right" @click="swipeRight()"
             :src="require('@/assets/ic_arrow_tariffs_right.svg')"
             :style="'opacity:'+rightOpacity+'%'"/>
        <img v-if="!isLoading" class="btn-left" @click="swipeLeft()"
             :src="require('@/assets/ic_arrow_tariffs_left.svg')"
             :style="'opacity:'+leftOpacity+'%'"/>
    </div>
    </card-content>
  </page-scaffold>


</template>

<script>
import PageScaffold from "./page-scaffold";
import CardContent from "./card-content";
import axios from "axios";
import PopUpMessage from "./pop-up-message";

export default {
  name: "page-bonus-spend",
  components: {PopUpMessage, CardContent, PageScaffold},
  data: () => {
    return {
      isEmptyList: false,
      isLoading: true,
      isChanging: false,
      scheduledTariffChanges: Object,
      title: "Как потратить бонусы?",
      isTv: Boolean,
      url: '',
      currentIndex: 0,
      rightOpacity: 100,
      leftOpacity: 30,
      tariffs: Array,
      amountTransfer: "",
      amountTariff: "",
      amountService: "",      
      touserID: "",
      serviceID: "",
      message: "Укажите сумму спсания",
      isMessageVisible: false
    }
  },
  methods: {
    onChange(event) {
    if(event.target.value == 6){
    this.amountService='3500'
    }
    if(event.target.value == 7){
    this.amountService='3500'
    }
    if(event.target.value == 40){
    this.amountService='6000'
    }
    
            console.log(event.target.value)
        },
    bonusTariff() {
      var isPhys =localStorage.userType === 'individual'
      
      if (isPhys) {
        if (this.amountTariff !== "")
        if (parseFloat(this.amountTariff) <= this.account.bonus)
        if (parseFloat(this.amountTariff) <= (this.account.totalCost/2))
          if (parseFloat(this.amountTariff) > 0)
            axios({
              method: "POST",
              params: {
                amount: this.amountTariff
              },
              url: "v1/bonus/bonusTariff",
              headers: {Authorization: `Bearer ` + localStorage.token},
            }).then(response => {
        console.log(response)
        this.getAccount()
        this.amountTariff=''
        this.message = "Бонусы успешно потрачены"
        this.isMessageVisible = true
      })
          else {
            this.message = "Сумма платежа должна быть больше 0 рублей"
            this.isMessageVisible = true
          }
          else {
            this.message = "Вы не можете потратить больше 50% вашего тарифа"
            this.isMessageVisible = true
          }
          else {
            this.message = "Вы не можете потратить больше своего баланса"
            this.isMessageVisible = true
          }
        else {
          this.message = "Укажите сумму списания"
          this.isMessageVisible = true
        }
      }
    },
    bonusTransfer() {
      var isPhys =localStorage.userType === 'individual'
      if (isPhys) {
        if (this.amountTransfer !== "")
        if (parseFloat(this.amountTransfer) <= this.account.bonus)
          if (parseFloat(this.amountTransfer) > 0)
            if (this.touserID !== "")
            axios({
              method: "POST",
              params: {
                amount: this.amountTransfer,
                touserID: this.touserID
              },
              url: "v1/bonus/bonusTransfer",
              headers: {Authorization: `Bearer ` + localStorage.token},
            }).then(response => {
        console.log(response)
        this.getAccount()
        this.message = "Бонусы успешно переведены"
        this.isMessageVisible = true
        this.amountTransfer = ''
        this.touserID = ''
      })
      else {
            this.message = "Укажите номер договора на который хотите перевести бонусы"
            this.isMessageVisible = true
          }
          else {
            this.message = "Сумма платежа должна быть больше 0 рублей"
            this.isMessageVisible = true
          }
          else {
            this.message = "Вы не можете потратить больше своего баланса"
            this.isMessageVisible = true
          }
        else {
          this.message = "Укажите сумму списания"
          this.isMessageVisible = true
        }
      }
    },
    bonusAddService() {
      var isPhys =localStorage.userType === 'individual'
      if (isPhys) {
        if (this.amountService !== "")
        if (parseFloat(this.amountService) <= this.account.bonus)
          if (parseFloat(this.amountService) > 0)
            if (this.serviceID !== "")
            axios({
              method: "POST",
              params: {
                amount: this.amountService,
                serviceID: this.serviceID
              },
              url: "v1/bonus/bonusAddService",
              headers: {Authorization: `Bearer ` + localStorage.token},
            }).then(response => {
        console.log(response)
        this.getAccount()
        this.amountService = ''
        this.message = "Бонусы успешно потрачены"
        this.isMessageVisible = true
      })
      else {
            this.message = "Выберите доп. услугу"
            this.isMessageVisible = true
          }
          else {
            this.message = "Сумма платежа должна быть больше 0 рублей"
            this.isMessageVisible = true
          }
          else {
            this.message = "Вы не можете потратить больше своего баланса"
            this.isMessageVisible = true
          }
        else {
          this.message = "Укажите сумму списания"
          this.isMessageVisible = true
        }
      }
    },
     swipeRight() {
      var parentElem = document.getElementById('cont').parentNode;
      var parentWidth = parentElem.offsetWidth;
      if (this.currentIndex < this.tariffs.length - Math.floor(parentWidth / 400)) {
        this.currentIndex++
        this.leftOpacity = 100
      }
      if (this.currentIndex === this.tariffs.length - Math.floor(parentWidth / 400)) {
        this.rightOpacity = 30
      } else
        this.rightOpacity = 100
    },

    swipeLeft() {
      if (this.currentIndex > 0) {
        this.rightOpacity = 100
        this.currentIndex--
      }
      if (this.currentIndex === 0) {
        this.leftOpacity = 30
      } else {
        this.leftOpacity = 100
      }

    },
  getAccount() {
      this.isLoading = true
      if (localStorage.userType) {
        this.isPhysical = localStorage.userType === "individual"
      }
      if (localStorage.mainCss) {
        this.mainCss = localStorage.mainCss
      }

      axios({
        method: "GET",
        url: "v2/account",
        headers: {Authorization: `Bearer ` + localStorage.token},
      }).then(response => {
        axios({
          method: "GET",
          url: "v1/advertisingOffers",
          headers: {Authorization: `Bearer ` + localStorage.token},
        }).then(response => {
          this.isLoading = false
          this.offers = response.data
        })
        this.account = response.data.account
        localStorage.userType = response.data.account.type
        localStorage.userName = response.data.account.userName
        this.userName = response.data.account.userName
        localStorage.userID = response.data.account.userID
        this.isTv = response.data.account.televisionTariffs.length !== 0
        this.isNet = response.data.account.internetTariffs.length !== 0
        if (response.data.account.type === "individual") {
          localStorage.mainCss = "main-page-phys"
          this.isPhysical = true
          this.mainCss = "main-page-phys"
        } else {
          this.isPhysical = false
          localStorage.mainCss = "main-page"
          this.mainCss = "main-page"

        }
      }).catch(function (error) {
        console.log(error.response.status === 401)
        {
          localStorage.token = ""
          localStorage.userID = ""
          localStorage.mainCss = "main-page-phys"
          localStorage.userName = "Имя пользователя"
          localStorage.userType = "individual"
          this.$router.push("/login")
        }
      });
    }
  },
  mounted() {
    this.isLoading = false
    this.getAccount()
  }
}
</script>

<style scoped>

.title {
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #4d9ec4;
  justify-self: center;
  font-size: 1.5rem;
}
.date {
  margin-top: 16px;
  font-family: "Mont", serif;
  font-weight: bold;
  color: #858585;
  justify-self: center;
  font-size: 1.2rem;
}
.sum {
  width: calc(100% - 48px);
  font-family: "Mont", serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: black;
  padding-top: 10px;
  padding-bottom: 8px;
  margin-top: 10px;
  margin-left: 24px;
  margin-right: 24px;
  text-align: center;
  border-width: 0;
  outline: none;
  height: 32px;
  background: #cccc;
  border-radius: 16px;
}
.btnn {
  cursor: pointer;
  margin-top: 16px;
  border-radius: 10px;
  background: #58B1DA;
  color: white;
  justify-self: center;
  font-family: "Mont", serif;
  padding: 16px 24px 13px 24px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}
.btn-left {
  cursor: pointer;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  height: max-content;
  position: absolute;
}

.btn-right {
  cursor: pointer;
  height: max-content;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}

.card {
  /*display: inline-block;*/
  margin-right: 32px;
  padding: 24px;
  position: relative;
  height: calc(100% - 48px);
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  background: white;
  box-shadow: 0 0 24px rgba(43, 89, 120, 0.15);
  border-radius: 24px;
  text-align: start;
}


.text1 {
  font-family: "Mont", serif;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.8rem;
  color: black;

}

.btn-container {
  position: absolute;
  bottom: 20px;
  width: 224px;
  cursor: pointer;
  box-shadow: 0 0 16px rgba(88, 177, 218, 0.6);
  height: 40px;
  border-radius: 12px;
  padding: 0 48px;
  display: grid;
  background: #58B1DA;
}

.btn-container .text {
  font-family: "Mont", serif;
  margin-top: 4px;
  font-weight: bold;
  color: white;
  font-size: 1rem;
  line-height: 1rem;
  align-self: center;
  justify-self: center;

}
</style>
