<template>
  <div class="message-row" v-bind:class="{ you: isMe === true, other: isMe === false }">
    <div class="message-text">{{ text }}</div>
    <div class="message-time">{{ convertTime(time) }}</div>
  </div>
</template>

<script>
export default {
  name: "item-message",
  props: {
    isMe: Boolean,
    text: String,
    photo: String,
    time: Number
  },
  methods: {
    convertTime(time) {
      if (time === -1) {
        return 'Отправка...';
      }
      const date = new Date(time);
      const hours = date.getHours();
      const minutes = "0" + date.getMinutes();
      return hours + ':' + minutes.substr(-2);
    }
  }
}
</script>

<style scoped>
.message-row {
  display: grid;
  grid-template-columns: 60%;
  margin-bottom: 12px;
}

.you {
  justify-content: end;
  justify-items: end;
}

.other {
  justify-content: start;
  justify-items: start;
}

.message-text {
  font-family: "Mont", serif;
  font-weight: bold;
  color: #2D666E;
  padding: 9px 14px;
  font-size: 1.6rem;
  margin-bottom: 5px;
}

.message-time {
  font-size: 0.9rem;
  color: #777777;
  font-family: "Mont", serif;
  font-weight: bold;
}

.you .message-text {
  background: #58B1DA;
  color: #ffffff;
  border-radius: 16px 16px 0 16px;
  text-align: right;
  font-size: 15px;
}

.other .message-text {
  font-family: "Mont", serif;
  font-weight: bold;
  color: #214f56;
  background: #f1f1f1;
  border-radius: 16px 16px 16px 0;
  font-size: 15px;
  text-align: left;
}
</style>
